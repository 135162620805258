import React, {useContext} from 'react'
import './style.scss'
import ApolloContext from '../../../../../context/ApolloContext'
import { Link } from 'gatsby'
import Loading from '../../../../../apollo/Loading'

function CustomBlock({ fields: field }) {
    const apollo = useContext(ApolloContext)

    const posts = apollo.data?.News.nodes.map((node, i) => { 
        if(i >= 3) return
        return (
            <article className={`single-post animated from-top delay-${i*2}  span-4 span-12-tablet`} key={node.id}>
              <div className="wrap">  
                <div className="box">
                    <h6 className="categories">
                        {node.categories.nodes.map((node,i) => {
                            if(i>0) {
                                return ' / ' + node.name
                            }
                            return node.name
                        })}
                    </h6>
                    <h3>
                        <Link to={node.uri}>
                            {node.title}
                        </Link>
                    </h3>
                    <div className="excerpt" dangerouslySetInnerHTML={{__html: node.excerpt}}/>
                </div>
                <div className="img-container">
                    <p className="link">
                        <Link to={node.uri}>
                            LEARN MORE
                        </Link>
                    </p>
                    <div className="img-wrapper">
                        <Link to={node.uri}>
                            <img srcSet={node.featuredImage.node.srcSet}/>
                        </Link>
                    </div>
                </div>
              </div>  
            </article>
        )
    })


	return (
        <div className="grid-12 ">
            {apollo.isLoading ? 
                <div className="span-12 loading">
                  <Loading />
                </div> 
            : 
            <>
                <div className="span-10 span-12-tablet grid-12 gap-2 gap-0-tablet">
                    {posts}
                </div>
                <div className="span-2 span-12-tablet relative">
                    <div className="slides-navi-box">
                        <p>03 <span className="up">06</span></p>
                        <Link to="news">
                            <button className="button">
                                VIEW ALL
                            </button>
                        </Link>
                    </div>
                </div>
                <span className="scroll-down">scroll down</span>
            </>
            }
        </div>
	)
}

export default CustomBlock