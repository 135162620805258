import React, {useContext} from 'react'
import './style.scss'
import ApolloContext from '../../../../../context/ApolloContext'
import NaviContext from '../../../../../context/NaviContext'
import { Link } from 'gatsby'
import Loading from '../../../../../apollo/Loading'
import Img from 'gatsby-image'

function CustomBlock({images: img, files: file, fields: field, wysiwygs: wysiwyg, maps: map, anchors: anchor}) {
    const apollo = useContext(ApolloContext)
    const naviContext = useContext(NaviContext)

    const posts = field.map((node, i) => {

        return (
            <article className="single-post span-3 span-12-tablet" key={node.id}>
                 <Link to={anchor[i]}>
                    <div className="img-container span-12">
                        <div className="img-wrapper">
                        <Img fluid={img[i]}
                          imgStyle={{objectFit: 'cover'}}
                          objectPosition='50% 50%'
                          className="image"
                        />
                        </div>
                    </div>
                    <div className="box span-12">
                        <h3>
                            {field[i]}
                        </h3>
                        <div className="excerpt" dangerouslySetInnerHTML={{__html: wysiwyg[i]}}/>
                    </div>
                    <p className="link">
                        LEARN MORE
                    </p>
                </Link>
            </article>
        )
    })

    const lastPost = 
    <article className="single-post last-single-post" key={apollo.data?.nursing?.nodes[0].id}>
        <Link to={apollo.data?.nursing?.nodes[0].uri}>
            <div className="img-container single">
                <div className="img-wrapper">
                        <img srcSet={apollo.data?.nursing?.nodes[0].featuredImage.node.srcSet} /> 
                </div>
            </div>
            <div className="box">
                <h3>
                    {apollo.data?.nursing?.nodes[0].title}
                </h3>
                <div className="excerpt" dangerouslySetInnerHTML={{__html: apollo.data?.nursing?.nodes[0].excerpt}}/>
            </div>
        </Link>
    </article>

	return (
        <div className="grid-12">
            {apollo.isLoading ? 
                <div className="span-12 loading">
                    <Loading />
                </div> 
            : 
            <>
                <div className="span-12 span-12-tablet">
                  {/* {
                    naviContext.locationPathname === '/nursing' ?
                      lastPost
                    :
                      null
                  } */}
                    
                </div>
                <div className="span-12 grid-12 span-12-tablet">
                    {posts}
                </div>
            </>
            }
        </div>
	)
}

export default CustomBlock