import React, { useContext } from 'react'
import './style.scss'
import ApolloContext from '../../../../../context/ApolloContext'
import { Link } from 'gatsby'
import Loading from '../../../../../apollo/Loading'
import { useInView } from 'react-intersection-observer'
import Intro from '../../../../../animations/Intro'

function CustomBlock({ fields: field }) {
  const apollo = useContext(ApolloContext)
  const [refInView, ioInView] = useInView({ triggerOnce: true })

  const posts = apollo.data?.News.nodes.map((node, i) => {
    if (i >= 3) return
    return (
      <Intro key={i} visible={ioInView} in={{fadeBottom: 500 }} delayIn={250 + i * 125} mounted={true} stay={true} className="full-width  span-4 span-12-mobile">
        <article className={`single-post `} key={node.id}>
          <div className="wrap">
            <div className="box">
              <h6 className="categories">
                {node.categories.nodes.map((node, i) => {
                  if (i > 0) {
                    return ' / ' + node.name
                  }
                  return node.name
                })}
              </h6>
              <h3>
                <Link to={node.uri}>
                  {node.title}
                </Link>
              </h3>
              <div className="excerpt" dangerouslySetInnerHTML={{ __html: node.excerpt }} />
            </div>
            <div className="img-container">
              <p className="link">
                <Link to={node.uri}>
                  LEARN MORE
                </Link>
              </p>
              <div className="img-wrapper">
                <Link to={node.uri}>
                  <img srcSet={node.featuredImage.node.srcSet} />
                </Link>
              </div>
            </div>
          </div>
        </article>
      </Intro>

    )
  })


  return (
    <div ref={refInView} className="grid-12 ">
      <div className='span-12 flex'>
        <div className='intro outline'>
            <Intro visible={ioInView} in={{ fade: 500 }} delayIn={250} mounted={true} stay={true} className="">
              <h1>NEWS & STORIES</h1>
            </Intro>   
        </div>
        <Link to="/news" className='button'>
            <Intro visible={ioInView} in={{ fade: 500 }} delayIn={250} mounted={true} stay={true} className="">
            <span>VIEW ALL</span>
            </Intro>   
        </Link>
      </div>
      <div className="span-10 span-12-tablet grid-12 gap-2 gap-0-mobile">
        {apollo.isLoading ?
            <div className="span-12 loading">
              <Loading />
            </div>
          :
            posts 
        }
      </div>
      <div className="span-2 span-12-tablet relative">
        <div className="slides-navi-box">
        </div>
      </div>
    </div>
  )
}

export default CustomBlock