import React, {useContext} from 'react'
import './style.scss'
import ApolloContext from '../../../../../context/ApolloContext'
import { Link } from 'gatsby'
import Loading from '../../../../../apollo/Loading'

function CustomBlock({ fields: field }) {

    let posts = null
    let hasNextPage = null
    const apollo = useContext(ApolloContext)
 

    if(apollo.data) {
        hasNextPage = apollo.data[field[0]].pageInfo.hasNextPage
        posts = apollo.data[field[0]].nodes.map((node, i) => {
            return (
                <article className="single-post span-6 span-12-mobile grid-12" key={node.id}>
                    <div className="img-container span-6 span-12-tablet span-12-mobile">
                        <div className="img-wrapper">
                            <Link to={node.uri}>
                                <img srcSet={node.featuredImage?.node.srcSet} alt={node.title} />
                            </Link>
                        </div>
                    </div>
                    <div className="box span-6 span-12-tablet">
                        <h3>
                            <Link to={node.uri}>
                                {node.title}
                            </Link>
                        </h3>
                        <div className="excerpt" dangerouslySetInnerHTML={{__html: node.excerpt}}/>
                        <p className="link">
                            <Link to={node.uri}>
                                LEARN MORE
                            </Link>
                        </p>
                    </div>
                
                </article>
            )
        })
    }

	return (
        <div className="grid-12 master-wrap">
            <>
                <div className="grid-12 ">
                    {posts}
                </div>
                {apollo.isLoading ? 
                    <div className="span-12 loading">
                      <Loading />
                    </div> 
                : 
                    null
                }
            </>
           
            {hasNextPage || apollo.isLoading ? 
                <button className="button" onClick={apollo.loadMore}>LOAD MORE</button>
            :
                <p className="span-12 message">No more posts</p>
            }
        </div>
	)
}

export default CustomBlock