import React from 'react'
import './style.scss'
import GalleryApi from '../../../../GalleryAPI'
import Slider from "react-slick"


function CustomBlock(props) {

	const settings = {
		dots: false,
		arrows: true,
		infinite: true,
		speed: 800,

		slidesToShow: 5,
		slidesToScroll: 5,
    adaptiveHeight: true,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
				}
			},
			{
				breakpoint: 720,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
		]
	}

  const gallerySlides = props?.gallery?.map((item,i) => {

    // const caption = <h3>{item.asset_title} {item.asset_date}</h3>
    const caption = <h3>{item.asset_title}</h3>

    let d = new Date(item.asset_date);

    return (
      <div className='relative' style={{position: "relative"}}>
        <GalleryApi gallery={item.asset_images} caption={caption}/>
        <div className='content' style={{padding: '0.2em'}}>
          <h5>{item.asset_title}
          {/* <br/> */}
          {/* <span className='fs-85'>{item.asset_date}</span> */}
          </h5>
        </div>
      </div>
    )
  })
 
	return gallerySlides ? (
    <div className='grid-12 gallery-center '>
      <div className='c3 title'>
        <h2 className="">Photo Gallery</h2>
      </div>
        <div className='c5'>
          <Slider {...settings}>
            {gallerySlides ? gallerySlides : null}
          </Slider>
        </div>
    </div>
	) 
  : null
}

export default CustomBlock

