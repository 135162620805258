import React, {useContext} from 'react'
import './style.scss'
import ApolloContext from '../../../../../context/ApolloContext'
import { Link } from 'gatsby'


function CustomBlock({ fields: field }) {
    const apollo = useContext(ApolloContext)
    const degreesFiltered = field ? apollo.data?.degrees?.nodes.filter(node => node.slug === field[0]) : null
    
        
    const posts = degreesFiltered?.[0]?.academics?.nodes?.map((node, i) => {
        return (
            <Link key={node.id} to={node.uri} className="single-post">
                <article className="flex column">
                    <div className="img-container"> 
                        <img srcSet={node.featuredImage?.node.srcSet} alt=''/>
                    </div>
                    <div className="content">
                        <h6>
                            {node.title}
                        </h6>
                        <p className="link">
                            LEARN MORE
                        </p>
                    </div>
                </article>
            </Link>
        )
    })



	return (
        <div className="grid-12 ">
            {apollo.isLoading ? 
                <div className="span-12 loading">loading...</div> 
            : 
            <div className="span-12 flex wrap c5">
                {posts}
            </div>
            }
        </div>
	)
}

export default CustomBlock